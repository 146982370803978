require('bootstrap/js/src/collapse');

var $ = window.jQuery;

$(document).ready(function () {
    // flag to check if we should listen to clicks on the filters
    let enableFilterRefresh = true;

    // the refine container
    let $publicationFilterContainer = $('#refine');
    let $publicationFilterForm = null;

    if ($publicationFilterContainer) {
        // markup for publication filter toggle button
        let publicationFilterButton = `
        <button class="collapse-btn" data-toggle="collapse" href="#refine" aria-controls="refine" aria-expanded="false">
            Refine Publications
        </button>
        `;

        // progressively enhance the collapse on the publication filter
        $publicationFilterContainer.addClass('collapse');
        $(publicationFilterButton).insertBefore($publicationFilterContainer);

        // find the form
        $publicationFilterForm = $publicationFilterContainer.find('form');

        // listen for changes on the inputs
        $publicationFilterForm.find('input').on('change', () => {
            if (!enableFilterRefresh) {
                return;
            }

            updatePublicationList('?' + $publicationFilterForm.serialize());
        });

        // listen for submits of the form
        $publicationFilterForm.on('submit', () => {
            if (!enableFilterRefresh) {
                return;
            }

            updatePublicationList('?' + $publicationFilterForm.serialize());
        });
    }

    // listen for changes on the pagination
    $('#pubs .pagination a').on('click', (event) => {
        event.preventDefault();
        updatePublicationList(event.currentTarget.href);
    });

    $('#pubs .pagination_options a').on('click', (event) => {
        event.preventDefault();
        updatePublicationList(event.currentTarget.href);
    });

    // update the publication list
    function updatePublicationList(url) {
        // get the publication list
        let $publicationList = $('#pubs');

        // fade out the list
        $publicationList.addClass('processing');

        // request the updated page
        $.get(url, (response, status, xhrReq) => {
            // turn the response into a jQuery object
            let $response = $(response);

            // find the new publications list in the response
            let $newPublications = $response.find('#pubs');

            // update the publication list
            $publicationList.replaceWith($newPublications).removeClass('processing');

            // set up the listeners on the pagination again
            $('#pubs .pagination a').on('click', (event) => {
                event.preventDefault();
                updatePublicationList(event.currentTarget.href);
            });

            $('#pubs .pagination_options a').on('click', (event) => {
                event.preventDefault();
                updatePublicationList(event.currentTarget.href);
            });

            // get the new URL from the backend
            let newUrl = xhrReq.getResponseHeader('Current-URL');

            // get an array of the filters
            let filters = [];
            if ($publicationFilterForm) {
                $publicationFilterForm.find(':checked').each(function () {
                    filters.push($(this).val());
                });
            }

            // push the state to history
            if (history.pushState && !!newUrl) {
                history.pushState({
                    name: newUrl,
                    page: 'Publications',
                    filter: filters,
                }, '', newUrl);
            }

            // scroll up to the top of the publications
            $('html, body').animate({
                scrollTop: $('#pubs').offset().top - 25,
            }, 400);

            // focus the pagination legend so assistive technology knows the page has changed
            $('#pubs .pagination_legend').focus();
        });
    }

    // listen to changes to the history
    $(window).on('popstate', function () {
        if (history.state && history.state.page === 'Publications') {
            var url = history.state.name;
            var filters = history.state.filter;

            if ($publicationFilterForm) {
                // disable the listeners on the checkboxes, so we can change them via this script
                enableFilterRefresh = false;

                $publicationFilterForm.find(':checked').attr('checked', false);
                filters.forEach(function (item) {
                    $publicationFilterForm.find('[value="' + item + '"]').click();
                });

                // re-enable the listeners again
                enableFilterRefresh = true;
            }

            // update the list of publications
            updatePublicationList(url);
        }
    });

    // enhance the publications filter form, hide the submit button
    $publicationFilterForm.find('[type="submit"]').addClass('offscreen');
});
