// jQuery used to hide > 10 datasets where JS is available

$(document).ready(function() {
    if (!location.href.match(/showfulldatasetsinfo/)) {
        $('.structured-data-datasets')
        .find('.dataset-card:gt(9)')
        .hide()

        // how many datasets do we have / the number of children of .structured-data-datasets
        var count = $('.structured-data-datasets').children().length;

        // include View More Datasets button logic
        if (count > 10) {
            // Display View More Datasets button
            var datasetsControlBtn = $('<button type="button" id="datasetsControl" class="btn btn-secondary btn-rounded btn-datasets-view-more">View More Datasets</button>');
            $('.view-more-datasets').prepend(datasetsControlBtn);

            $('#datasetsControl').click(function() {
                var current = $('#datasetsControl').html();

                if (current == 'View More Datasets') {
                    $('#datasetsControl').addClass('btn-datasets-view-less').removeClass('btn-datasets-view-more').html('View Less Datasets');
                    $('.structured-data-datasets').children(':hidden').show();
                    $( "ul li:nth-child(11)" ).focus();
                } else {
                    $('#datasetsControl').addClass('btn-datasets-view-more').removeClass('btn-datasets-view-less').html('View More Datasets');
                    $('.structured-data-datasets').find('.dataset-card:gt(9)').hide().end();
                }
            });
        }
    }
});
